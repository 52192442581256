










import { Component, Vue, Prop } from 'vue-property-decorator'
import PrimeVueInputNumber from 'primevue/inputnumber'

@Component({
  components: {
    PrimeVueInputNumber
  }
})
export default class InputNumber extends Vue {
  @Prop({ required: true })
  public value!: string

  @Prop({ required: true })
  public fieldId!: string

  @Prop({ required: true })
  public placeholder!: string

  @Prop({ required: true })
  public label!: string

  @Prop({ required: false })
  public error: string | undefined

  @Prop({ required: false, default: 'decimal' })
  public mode!: 'decimal' | 'currency'

  @Prop({ required: false })
  public currency: string | undefined

  @Prop({ required: false })
  public suffix: string | undefined

  @Prop({ required: false, default: false })
  public showButtons!: boolean

  onInput (newVal: string) {
    this.$emit('input', newVal)
  }
}
